<mat-dialog-content>
  <div class="card">
    <form #updateUserForm="ngForm">
      <div class="card-block">
        <div class="form-group row">
          <label for="inputName" class="col-sm-2 col-form-label">{{ "admin.createUser.table.name" | translate }}</label>
          <div class="col-sm-10">
            <input required type="text" #name="ngModel" class="form-control" id="inputName" name="name" [(ngModel)]="activeName" placeholder="{{ 'admin.createUser.table.name' | translate }}">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
              <div *ngIf="name.errors.required">
                {{ 'admin.createUser.error.name.required' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputEmail" class="col-sm-2 col-form-label">{{ "admin.createUser.table.email" | translate }}</label>
          <div class="col-sm-10">
            <input required email type="email" #email="ngModel" disabled class="form-control" id="inputEmail" name="email" [(ngModel)]="activeEmail" placeholder="{{ 'admin.createUser.table.email' | translate }}">
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
              <div *ngIf="email.errors.required">
                {{ 'admin.createUser.error.email.required' | translate }}
              </div>
              <div *ngIf="email.errors.email">
                {{ 'admin.createUser.error.email.invalid' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword" class="col-sm-2 col-form-label">{{ "admin.createUser.table.password" | translate }}</label>
          <div class="col-sm-10">
            <input type="password" autocomplete="off" #password="ngModel" disabled class="form-control" id="inputPassword" name="password" [(ngModel)]="activePassword" placeholder="{{ 'admin.createUser.table.password' | translate }}">
          </div>
        </div>
        <div class="form-group row">
          <label for="inputOrganization" class="col-sm-2 col-form-label">{{ "admin.createUser.table.organization" | translate }}</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="inputOrganization" name="organization" [(ngModel)]="activeOrganization" placeholder="{{ 'admin.createUser.table.organization' | translate }}">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-2">{{ "admin.createUser.table.roles" | translate }}</div>
          <div class="col-sm-10">
            <div *ngFor="let role of roles" class="form-check">
              <input class="form-check-input" type="checkbox" id="{{ 'role' + role }}" [checked]="activeRoles.indexOf(role) > -1" (change)="onRoleSelectionChange(role)">
              <label class="form-check-label" for="{{ 'role' + role }}">
                {{"role." + role | translate}}
              </label>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-2">{{ "admin.createUser.table.regions" | translate }}</div>
          <div class="col-sm-10">
            <div *ngFor="let region of regions" class="form-check">
              <input class="form-check-input" type="checkbox" id="{{ 'region' + region }}" [checked]="activeRegions.indexOf(region) > -1" (change)="onRegionSelectionChange(region)">
              <label class="form-check-label" for="{{ 'region' + region }}">
                {{ regionsService.getRegionName(region) }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer -->
      <div class="card-footer">
        <button [disabled]="!updateUserForm.valid" type="submit" class="btn btn-sm btn-primary px-3" (click)="updateUser()">{{
          "admin.createUser.button.update" | translate }}</button>
        <button type="button" class="btn btn-sm btn-secondary px-3" (click)="closeDialog('')">{{"admin.createUser.button.cancel" | translate }}</button>
      </div>
    </form>
  </div>
</mat-dialog-content>
<header class="app-header navbar" [style]="getStyle()">
  <button class="navbar-toggler mobile-sidebar-toggler hidden-lg-up" type="button">&#9776;</button>
  <a class="navbar-brand" href="#" [style]="getStyle()"></a>

  <ul class="nav navbar-nav hidden-md-down">
<!--
    <li class="nav-item">
      <a class="nav-link navbar-toggler sidebar-toggler" href="#">&#9776;</a>
    </li>
-->
    <li class="nav-item px-1">
      <a class="nav-link" routerLinkActive="active" [routerLink]="['/bulletins']">{{ "sidebar.bulletins" | translate }}</a>
    </li>
    <li *ngIf="settingsService.getShowObservations() && authenticationService.isEuregio()" class="nav-item px-1">
      <a class="nav-link" routerLinkActive="active" [routerLink]="['/observations']">{{ "sidebar.observations" | translate }}</a>
    </li>
    <li *ngIf="authenticationService.isEuregio()" class="nav-item px-1">
      <a class="nav-link" routerLinkActive="active" [routerLink]="['/modelling']">{{ "sidebar.modelling" | translate }}</a>
    </li>
  </ul>

  <ul class="nav navbar-nav ml-auto">
<!--
    <li class="nav-item hidden-md-down">
      <a class="nav-link" href="#"><i class="fa fa-bell"></i><span class="badge badge-pill badge-danger">5</span></a>
    </li>
-->
<li class="nav-item dropdown" dropdown (onToggle)="toggled()" style="padding-right: 10px">
  <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle>
    <i class="fa fa-language"></i>
  </a>
  <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
    <div class="dropdown-header text-center"><strong>{{ "menu.language" | translate }}</strong></div>
    <a class="dropdown-item" (click)='settingsService.setLangString("it")'><i class="fa fa-comment"></i>&nbsp;{{ "menu.it" | translate }}<i *ngIf="settingsService.getLangString()==='it'" class="fa fa-check float-right"></i></a>
    <a class="dropdown-item" (click)='settingsService.setLangString("de")'><i class="fa fa-comment"></i>&nbsp;{{ "menu.de" | translate }}<i *ngIf="settingsService.getLangString()==='de'" class="fa fa-check float-right"></i></a>
    <a class="dropdown-item" (click)='settingsService.setLangString("en")'><i class="fa fa-comment"></i>&nbsp;{{ "menu.en" | translate }}<i *ngIf="settingsService.getLangString()==='en'" class="fa fa-check float-right"></i></a>
    <a class="dropdown-item" (click)='settingsService.setLangString("fr")'><i class="fa fa-comment"></i>&nbsp;{{ "menu.fr" | translate }}<i *ngIf="settingsService.getLangString()==='fr'" class="fa fa-check float-right"></i></a>
    <a class="dropdown-item" (click)='settingsService.setLangString("es")'><i class="fa fa-comment"></i>&nbsp;{{ "menu.es" | translate }}<i *ngIf="settingsService.getLangString()==='es'" class="fa fa-check float-right"></i></a>
    <a class="dropdown-item" (click)='settingsService.setLangString("ca")'><i class="fa fa-comment"></i>&nbsp;{{ "menu.ca" | translate }}<i *ngIf="settingsService.getLangString()==='ca'" class="fa fa-check float-right"></i></a>
    <a class="dropdown-item" (click)='settingsService.setLangString("oc")'><i class="fa fa-comment"></i>&nbsp;{{ "menu.oc" | translate }}<i *ngIf="settingsService.getLangString()==='oc'" class="fa fa-check float-right"></i></a>
  </div>
</li>
<li class="nav-item dropdown" dropdown (onToggle)="toggled()" style="padding-right: 10px">
      <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle>
        <img src="{{ authenticationService.getUserImageSanitized() }}" class="img-avatar">
        <span class="hidden-md-down">{{ authenticationService.getUsername() }}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>{{ "menu.region" | translate }}</strong></div>

        <div *ngFor="let region of authenticationService.getCurrentAuthorRegions()">
          <a class="dropdown-item" (click)='changeRegion(region)'><i class="fa fa-globe"></i>&nbsp;{{ regionsService.getRegionName(region.id) }}<i *ngIf="authenticationService.getActiveRegionId()===region.id" class="fa fa-check float-right"></i></a>
        </div>

        <div class="dropdown-header text-center"><strong>{{ "menu.education" | translate }}</strong></div>
        <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/education']"><i class="fa fa-graduation-cap"></i> {{ "menu.education" | translate }}</a>

        <div class="dropdown-header text-center"><strong>{{ "menu.settings" | translate }}</strong></div>

        <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/settings']"><i class="fa fa-gear"></i> {{ "menu.settings" | translate }}</a>
        <a *ngIf="authenticationService.isCurrentUserInRole(constantsService.roleAdmin)" class="dropdown-item" routerLinkActive="active" [routerLink]="['/admin']"><i class="fa fa-user-secret"></i>{{ "menu.admin" | translate }}</a>
        <a class="dropdown-item" href="#" routerLink="/pages/login" (click)="logout()"><i class="fa fa-sign-out"></i> {{ "menu.logout" | translate }}</a>
      </div>
    </li>
    <li *ngIf="showChat && authenticationService.isEuregio()" class="nav-item">
      <a *ngIf="authenticationService.getActiveRegionId() && authenticationService.getActiveRegionId() !== undefined" class="nav-link"><i class="fa fa-comments aside-menu-toggler"></i><span *ngIf="showBadge()" class="badge badge-pill badge-danger">{{ chatService.getNewMessageCountSum(authenticationService.getActiveRegionId()) }}</span></a>
    </li>
  </ul>

</header>

<div class="app-body">

  <!-- Menu left -->
  <div class="sidebar">
    <nav class="sidebar-nav">
      <ul class="nav">
        <li class="divider"></li>
<!--
        <li class="nav-title">
          {{ "sidebar.bulletins" | translate }}
        </li>
-->
        <li class="nav-item nav-dropdown" routerLinkActive="open">
          <a class="nav-link mobile-sidebar-toggler" routerLinkActive="active" [routerLink]="['/bulletins']"><i class="fa fa-snowflake-o"></i> {{ "sidebar.bulletins" | translate }}</a>
        </li>
        <li *ngIf="settingsService.getShowObservations() && authenticationService.isEuregio()" class="nav-item nav-dropdown" routerLinkActive="open">
          <a class="nav-link mobile-sidebar-toggler" routerLinkActive="active" [routerLink]="['/observations']"><i class="fa fa-eye"></i> {{ "sidebar.observations" | translate }}</a>
        </li>
        <li *ngIf="authenticationService.isEuregio()" class="nav-item nav-dropdown" routerLinkActive="open">
          <a class="nav-link mobile-sidebar-toggler" routerLinkActive="active" [routerLink]="['/modelling']"><i class="fa fa-area-chart"></i> {{ "sidebar.modelling" | translate }}</a>
        </li>
      </ul>
    </nav>
  </div>

  <!-- Main content -->
  <main class="main">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.conainer-fluid -->
  </main>

  <!-- Sidebar right -->
  <aside class="aside-menu" style="overflow: auto" *ngIf="authenticationService.getActiveRegionId() && authenticationService.getActiveRegionId() !== undefined && authenticationService.getActiveRegionId() !== constantsService.codeAran" >
<!--
    <div *ngIf="chatService.getNumberOfActiveUsers() > 0" class="p-1">
      <div class="message font-weight-bold text-muted small">{{ "chat.activeUsers" | translate }}</div>
      <div *ngFor="let user of chatService.getActiveUsers()" class="message">
        <div>
          <div>
            <small>{{ user }}</small>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="chatService.getNumberOfActiveUsers() == 0" class="p-1">
      <div class="message font-weight-bold text-muted small">{{ "chat.activeUsers" | translate }}</div>
      <div class="message">
        <div>
          <div>
            <small>-</small>
          </div>
        </div>
      </div>
    </div>
    <hr style="margin: 0px">
-->
    <tabset>
      <!-- ALL -->
      <tab *ngIf="authenticationService.getActiveRegionId() && authenticationService.getActiveRegionId() !== undefined">
        <ng-template tabHeading>{{ "chat.all" | translate }}<span *ngIf="showBadge()" class="badge badge-pill badge-danger">{{ chatService.getNewMessageCount() }}</span></ng-template>
        <div class="p-1">
          <div class="message">
            <textarea type="text" rows="2" class="form-control" id="chatMessage" placeholder='{{ "chat.placeholder" | translate }}' [(ngModel)]="message" (click)="focusChat($event)"></textarea>
            <button type="button" class="btn btn-primary btn-sm btn-block" (click)="sendChatMessage()">{{ "chat.send" | translate }}</button>
            <hr>
          </div>
          <div *ngFor="let chatMessage of chatService.getChatMessages()">
            <div class="message">
              <div>
                <small class="text-muted">{{ chatMessage.getUsername() }}</small>
                <small class="text-muted float-right mt-q">{{ chatMessage.getTime() | localizedDate: 'hh:mm dd/MM'}}</small>
              </div>
              <div style="white-space: pre-wrap">{{ chatMessage.getText() }}</div>
            </div>
            <hr>
          </div>
        </div>
      </tab>
      <!-- TIROL -->
      <tab *ngIf="authenticationService.getActiveRegionId() === constantsService.codeSouthTyrol || authenticationService.getActiveRegionId() === constantsService.codeTrentino">
        <ng-template tabHeading>{{ "chat.AT-07" | translate }}<span *ngIf="showBadge(constantsService.codeTyrol)" class="badge badge-pill badge-danger">{{ chatService.getNewMessageCount(constantsService.codeTyrol) }}</span></ng-template>
<!--        <div *ngIf="chatService.getNumberOfActiveUsersForRegion(constantsService.codeTyrol) > 0" class="p-1">
          <div class="message font-weight-bold text-muted small">{{ "chat.activeUsers" | translate }}</div>
          <div *ngFor="let user of chatService.getActiveUsersForRegion(constantsService.codeTyrol)" class="message">
            <div>
              <div>
                <small>{{ user }}</small>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="chatService.getNumberOfActiveUsersForRegion(constantsService.codeTyrol) == 0" class="p-1">
          <div class="message font-weight-bold text-muted small">{{ "chat.activeUsers" | translate }}</div>
          <div class="message">
            <div>
              <div>
                <small>-</small>
              </div>
            </div>
          </div>
        </div>
        <hr style="margin: 0px"> -->
        <div class="p-1">
          <div class="message">
            <textarea type="text" rows="2" class="form-control" id="chatMessage" placeholder='{{ "chat.placeholder" | translate }}' [(ngModel)]="message" (click)="focusChat($event, constantsService.codeTyrol)"></textarea>
            <button type="button" class="btn btn-primary btn-sm btn-block" (click)="sendChatMessage(constantsService.codeTyrol)">{{ "chat.send" | translate }}</button>
            <hr>
          </div>
          <div *ngFor="let chatMessage of chatService.getChatMessages(constantsService.codeTyrol)">
            <div class="message">
              <div>
                <small class="text-muted">{{ chatMessage.getUsername() }}</small>
                <small class="text-muted float-right mt-q">{{ chatMessage.getTime() | localizedDate: 'hh:mm dd/MM'}}</small>
              </div>
              <div style="white-space: pre-wrap">{{ chatMessage.getText() }}</div>
            </div>
            <hr>
          </div>
        </div>
      </tab>
      <!-- SOUTH TYROL -->
      <tab *ngIf="authenticationService.getActiveRegionId() === constantsService.codeTyrol || authenticationService.getActiveRegionId() === constantsService.codeTrentino">
        <ng-template tabHeading>{{ "chat.IT-32-BZ" | translate }}<span *ngIf="showBadge(constantsService.codeSouthTyrol)" class="badge badge-pill badge-danger">{{ chatService.getNewMessageCount(constantsService.codeSouthTyrol) }}</span></ng-template>
<!--        <div *ngIf="chatService.getNumberOfActiveUsersForRegion(constantsService.codeSouthTyrol) > 0" class="p-1">
          <div class="message font-weight-bold text-muted small">{{ "chat.activeUsers" | translate }}</div>
          <div *ngFor="let user of chatService.getActiveUsersForRegion(constantsService.codeSouthTyrol)" class="message">
            <div>
              <div>
                <small>{{ user }}</small>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="chatService.getNumberOfActiveUsersForRegion(constantsService.codeSouthTyrol) == 0" class="p-1">
          <div class="message font-weight-bold text-muted small">{{ "chat.activeUsers" | translate }}</div>
          <div class="message">
            <div>
              <div>
                <small>-</small>
              </div>
            </div>
          </div>
        </div>
        <hr style="margin: 0px"> -->
        <div class="p-1">
          <div class="message">
            <textarea type="text" rows="2" class="form-control" id="chatMessage" placeholder='{{ "chat.placeholder" | translate }}' [(ngModel)]="message" (click)="focusChat($event, constantsService.codeSouthTyrol)"></textarea>
            <button type="button" class="btn btn-primary btn-sm btn-block" (click)="sendChatMessage(constantsService.codeSouthTyrol)">{{ "chat.send" | translate }}</button>
            <hr>
          </div>
          <div *ngFor="let chatMessage of chatService.getChatMessages(constantsService.codeSouthTyrol)">
            <div class="message">
              <div>
                <small class="text-muted">{{ chatMessage.getUsername() }}</small>
                <small class="text-muted float-right mt-q">{{ chatMessage.getTime() | localizedDate: 'hh:mm dd/MM'}}</small>
              </div>
              <div style="white-space: pre-wrap">{{ chatMessage.getText() }}</div>
            </div>
            <hr>
          </div>
        </div>
      </tab>
      <!-- TRENTINO -->
      <tab *ngIf="authenticationService.getActiveRegionId() === constantsService.codeTyrol || authenticationService.getActiveRegionId() === constantsService.codeSouthTyrol">
        <ng-template tabHeading>{{ "chat.IT-32-TN" | translate }}<span *ngIf="showBadge(constantsService.codeTrentino)" class="badge badge-pill badge-danger">{{ chatService.getNewMessageCount(constantsService.codeTrentino) }}</span></ng-template>
<!--        <div *ngIf="chatService.getNumberOfActiveUsersForRegion(constantsService.codeTrentino) > 0" class="p-1">
          <div class="message font-weight-bold text-muted small">{{ "chat.activeUsers" | translate }}</div>
          <div *ngFor="let user of chatService.getActiveUsersForRegion(constantsService.codeTrentino)" class="message">
            <div>
              <div>
                <small>{{ user }}</small>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="chatService.getNumberOfActiveUsersForRegion(constantsService.codeTrentino) == 0" class="p-1">
          <div class="message font-weight-bold text-muted small">{{ "chat.activeUsers" | translate }}</div>
          <div class="message">
            <div>
              <div>
                <small>-</small>
              </div>
            </div>
          </div>
        </div>
        <hr style="margin: 0px"> -->
        <div class="p-1">
          <div class="message">
            <textarea type="text" rows="2" class="form-control" id="chatMessage" placeholder='{{ "chat.placeholder" | translate }}' [(ngModel)]="message" (click)="focusChat($event, constantsService.codeTrentino)"></textarea>
            <button type="button" class="btn btn-primary btn-sm btn-block" (click)="sendChatMessage(constantsService.codeTrentino)">{{ "chat.send" | translate }}</button>
            <hr>
          </div>
          <div *ngFor="let chatMessage of chatService.getChatMessages(constantsService.codeTrentino)">
            <div class="message">
              <div>
                <small class="text-muted">{{ chatMessage.getUsername() }}</small>
                <small class="text-muted float-right mt-q">{{ chatMessage.getTime() | localizedDate: 'hh:mm dd/MM'}}</small>
              </div>
              <div style="white-space: pre-wrap">{{ chatMessage.getText() }}</div>
            </div>
            <hr>
          </div>
        </div>
      </tab>
    </tabset>
  </aside>
</div>

<ng-template #changeRegionTemplate>
  <div class="modal-body text-center">
    <p>{{ "changeRegionDialog.message" | translate }}</p>
    <button type="button" class="btn btn-primary" (click)="changeRegionModalConfirm()">{{ "changeRegionDialog.accept" | translate }}</button>
    <button type="button" class="btn btn-default" (click)="changeRegionModalDecline()">{{ "changeRegionDialog.reject" | translate }}</button>
  </div>
</ng-template>
